<template>
  <div>
    <b-row class="m-0 justify-content-center">
      <div
        class="
          d-flex
          align-items-center
          justify-content-between
          col-md-9 col-12
          p-1
          pl-0
        "
      >
        <div class="d-flex align-items-center">
          <h2
            class="content-header-title float-left m-0 pr-1 mr-1"
            style="border-right: 1px solid #e2e2e2"
          >
            {{ $route.meta.pageTitle }}
          </h2>
          <div class="content-header-search mr-1">
            <h3 class="m-0">
              {{
                civilityTranslate(contactForm.civility) +
                contactForm.firstName +
                " " +
                contactForm.lastName
              }}
            </h3>
          </div>
        </div>
        <div class="content-header-actions d-flex">
          <feather-icon
            class="cursor"
            icon="XIcon"
            size="24"
            @click="cancel()"
          />
        </div>
      </div>
    </b-row>
    <div
      v-if="isLoadingContact || isCreatingContact || isUpdatingContact"
      class="content-loader-center m-0 h-100"
    >
      <div class="text-center flex-center">
        <div class="loading-bg-inner">
          <div class="loader">
            <div class="outer"></div>
            <div class="middle"></div>
            <div class="inner"></div>
          </div>
        </div>
        <div class="mt-5">
          <br /><br /><br />
          Chargement des détails du contact...
        </div>
      </div>
    </div>
    <b-row
      v-else
      class="content-scrollable-sticky m-0 justify-content-center"
      style="height: calc(100% - 120px)"
    >
      <b-col cols="12" md="9" class="content-scrollable-sticky-main h-100">
        <validation-observer
          ref="formCreateContact"
          class="h-100"
          style="overflow-x: hidden"
        >
          <b-form class="h-100" style="overflow-x: hidden">
            <b-row class="my-0">
              <b-col cols="12" md="6" class="py-0 mb-1">
                <b-form-group label="Civilité" label-for="civility">
                  <validation-provider #default="{ errors }" name="Civility">
                    <v-select
                      id="civility"
                      :state="errors.length &gt; 0 ? false : null"
                      :reduce="type =&gt; type.value"
                      v-model="contactForm.civility"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="optionCivility"
                    ></v-select
                    ><small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="my-0 col-mobile">
              <b-col cols="12" md="6" class="py-0 mb-1">
                <b-form-group label="Prénom *" label-for="firstName">
                  <validation-provider
                    #default="{ errors }"
                    name="firstName"
                    rules="required"
                  >
                    <b-form-input
                      id="firstName"
                      v-model="contactForm.firstName"
                      :state="errors.length &gt; 0 ? false : null"
                      autocomplete="nope"
                      aria-autocomplete="nope"
                    ></b-form-input>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6" class="py-0 mb-1">
                <b-form-group label="Nom *" label-for="lastName">
                  <validation-provider
                    #default="{ errors }"
                    name="lastName"
                    rules="required"
                  >
                    <b-form-input
                      id="lastName"
                      v-model="contactForm.lastName"
                      :state="errors.length &gt; 0 ? false : null"
                      autocomplete="nope"
                      aria-autocomplete="nope"
                    ></b-form-input>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="my-0 col-mobile">
              <b-col cols="12" md="6" class="py-0 mb-1">
                <b-form-group
                  label="Téléphone principal"
                  label-for="phoneNumber"
                >
                  <b-form-input
                    id="phoneNumber"
                    v-model="contactForm.phoneNumber"
                    autocomplete="nope"
                    aria-autocomplete="nope"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6" class="py-0 mb-1">
                <b-form-group
                  label="Téléphone secondaire"
                  label-for="phoneNumber"
                >
                  <b-form-input
                    id="phoneNumber"
                    v-model="contactForm.secondaryPhoneNumber"
                    autocomplete="nope"
                    aria-autocomplete="nope"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="my-0 col-mobile">
              <b-col cols="12" class="py-0 mb-1">
                <b-form-group label="Adresse e-mail" label-for="email">
                  <validation-provider
                    #default="{ errors }"
                    name="email"
                    rules="email"
                  >
                    <b-form-input
                      id="email"
                      v-model="contactForm.email"
                      :state="errors.length &gt; 0 ? false : null"
                      autocomplete="nope"
                      aria-autocomplete="nope"
                    ></b-form-input>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
        <b-row class="my-0 col-mobile">
          <b-col cols="12" class="py-0">
            <div
              class="d-flex justify-content-between"
              style="border-top: 1px solid #e2e2e2; padding-top: 0.5rem"
            >
              <div>
                <b-button variant="outline-primary" @click="cancel()">
                  <feather-icon icon="ArrowLeftIcon" />
                  Annuler
                </b-button>
                <b-button
                  v-if="contactForm.id"
                  variant="outline-danger"
                  class="btn-icon ml-2"
                  @click="
                    archiveContactLocal(
                      contactForm.id,
                      contactForm.firstName + ' ' + contactForm.lastName
                    )
                  "
                >
                  <feather-icon icon="ArchiveIcon" />
                </b-button>
              </div>

              <b-button variant="primary" @click="create">
                <feather-icon icon="SaveIcon" v-if="contactForm.id" />
                <feather-icon icon="PlusIcon" v-else />
                {{ contactForm.id ? "Modifier" : "Ajouter" }}
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { debounce } from "lodash";
import Autocomplete from "vuejs-auto-complete";
import vSelect from "vue-select";
import { ECivility } from "@/types/api-orisis/enums/enums";
import Ripple from "vue-ripple-directive";
import {
  ValidationProvider,
  ValidationObserver,
  configure,
  localize,
} from "vee-validate";
import { required, url, email } from "@validations";
configure({
  generateMessage: localize("fr", {
    messages: {
      url: "Ce champ doit être une URL",
      required: "Ce champ est requis",
      email: "Ce champ doit être un email",
    },
  }),
});
localize("fr");

import { mapGetters, mapActions } from "vuex";
import { BFormCheckbox, BFormSpinbutton } from "bootstrap-vue";
import { mask } from "vue-the-mask";
import { civilityTranslate } from "@/types/api-orisis/library/TranslateOperations.ts";

export default {
  props: {
    id: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      optionCivility: [
        {
          label: ECivility[ECivility.Monsieur],
          value: ECivility.Monsieur,
        },
        {
          label: ECivility[ECivility.Madame],
          value: ECivility.Madame,
        },
        {
          label: ECivility[ECivility.Autre],
          value: ECivility.Autre,
        },
      ],
      required,
      url,
      email,
      dataOrigine: {},
      contactForm: {
        id: 0,
        civility: 0,
        lastName: "",
        firstName: "",
        phoneNumber: "",
        secondaryPhoneNumber: "",
        email: "",
      },
    };
  },
  async created() {
    this.popupContactEvent(this.id);
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll, true);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll, true);
  },
  methods: {
    civilityTranslate,
    onScroll(e) {
      let mainNavLinks = document.querySelectorAll(
        ".content-scrollable-sticky-nav ul li a"
      );
      let fromTop = e.target.scrollTop;

      mainNavLinks.forEach((link) => {
        let section = document.querySelector(link.hash);
        if (
          section.offsetTop <= fromTop &&
          section.offsetTop + section.offsetHeight > fromTop
        ) {
          link.classList.add("current");
        } else {
          link.classList.remove("current");
        }
      });
    },
    popupContactEvent(id) {
      if (id != "") {
        this.getContactById({ contactId: id }).then((res) => {
          this.contactForm = res;
          this.dataOrigine = JSON.stringify(this.contactForm);
        });
      } else {
        this.initializeForm();
      }
    },
    initializeForm() {
      this.contactForm = {
        id: 0,
        civility: 0,
        lastName: "",
        firstName: "",
        phoneNumber: "",
        secondaryPhoneNumber: "",
        email: "",
        activity: "",
      };
      this.dataOrigine = JSON.stringify(this.contactForm);
    },
    ...mapActions([
      "createContact",
      "updateContact",
      "getContactById",
      "archiveContacts",
    ]),
    create(e) {
      e.preventDefault();
      this.$refs.formCreateContact
        .validate()
        .then((success1) => {
          if (success1) {
            if (this.contactForm.id)
              this.updateContact({ contact: this.contactForm });
            else this.createContact({ contact: this.contactForm });
            this.$nextTick(() => {
              if (this.$route.params.routeOrigine) {
                this.$tabs.close({ to: "/" + this.$route.params.routeOrigine });
              } else {
                this.$tabs.close({ to: "/directory/contacts" });
              }
            });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    archiveContactLocal(id, label) {
      this.$bvModal
        .msgBoxConfirm(
          "Vous pourrez à tout moment restaurer cette fiche en consultant la liste de vos archives.",
          {
            title: 'Êtes-vous sûr de vouloir archiver "' + label + '" ?',
            size: "sm",
            okVariant: "danger",
            okTitle: "Archiver",
            cancelTitle: "Annuler",
            cancelVariant: "outline-primary",
            hideHeaderClose: true,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.archiveContacts({ contactIds: [id] });
            if (this.$route.params.routeOrigine) {
              this.$tabs.close({ to: "/" + this.$route.params.routeOrigine });
            } else {
              this.$tabs.close();
            }
          }
        });
    },

    // Controls from
    cancel() {
      if (this.dataOrigine == JSON.stringify(this.contactForm)) {
        if (this.$route.params.routeOrigine) {
          this.$tabs.close({ to: "/" + this.$route.params.routeOrigine });
        } else {
          this.$tabs.close();
        }
      } else {
        this.$bvModal
          .msgBoxConfirm("Cette action est définitive et irréversible.", {
            title:
              "Êtes-vous sûr de vouloir quitter l'édition sans enregistrer ?",
            size: "sm",
            okVariant: "primary",
            okTitle: "Oui",
            cancelTitle: "Annuler",
            cancelVariant: "outline-primary",
            hideHeaderClose: true,
            centered: true,
          })
          .then((value) => {
            if (value) {
              if (this.$route.params.routeOrigine) {
                this.$tabs.close({ to: "/" + this.$route.params.routeOrigine });
              } else {
                this.$tabs.close();
              }
            }
          });
      }
    },
  },
  computed: {
    ...mapGetters([
      "isLoadingContact",
      "isLoadingContact",
      "contactsTypesList",
      "contactsList",
      "isCreatingContact",
      "isUpdatingContact",
    ]),
  },
  components: {
    vSelect,
    ValidationObserver,
    ValidationProvider,
    BFormCheckbox,
    BFormSpinbutton,
    Autocomplete,
  },
  directives: {
    Ripple,
    mask,
  },
};
</script>
<style lang="scss">
@media screen and (max-width: 768px) {
  .col-mobile {
    flex-direction: column !important;
  }
}
.content-scrollable-sticky {
  // display: grid;
  // grid-template-columns: min-content 1fr;
  position: relative;
  .content-scrollable-sticky-nav {
    white-space: nowrap;
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      li a {
        display: block;
        padding: 1rem 0rem;
        color: white;
        text-decoration: none;
      }
      li a.current {
        background: black;
      }
    }
  }
}

/* Only stick if you can fit */
@media (min-height: 300px) {
  .content-scrollable-sticky-nav ul {
    position: sticky;
    top: 0;
  }
}
</style>
